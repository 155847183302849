
.button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    background: linear-gradient(180deg, #1f584b, #17493d);
    color: #c2e9e0;
    font-family: "Roboto Condensed", sans-serif;
    cursor: pointer;
  }
  
  .buttonAlt {
    font-family: "Roboto Condensed", sans-serif;
    border: none;
    background: transparent;
    color: #c2e9e0;
    cursor: pointer;
  }
  
  button:hover {
    background: linear-gradient(180deg, #1b5346, #113c32);
  }
  
  .buttonAlt:hover {
    background: transparent;
    color: #91e1d0;
  }