.container {
  display: flex; /* or inline-flex */
}

.form {
  padding: 1rem;
  max-width: 40rem;
  margin: 2rem auto;
  border-radius: 4px;
  background: linear-gradient(180deg, #307e6c, #2b996d);
}

.input-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1.5rem;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

