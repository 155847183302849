
@media (max-width: 480px) {
  .hidden-mobile {
    display: none;
  }
}

.result {
    max-width: 50rem;
    margin: 2rem auto;
    padding: 1rem;
    table-layout: fixed;
    border-spacing: 1rem;
    text-align: right;
  }
  
  .result thead {
    font-size: 0.8rem;
    color: #83e6c0;
  }
  
  .result tbody {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 0.85rem;
    color: #c2e9e0;
  }